@import
  '../../assets/styles/helpers/variables',
  '../../assets/styles/helpers/mixins';

.noCoversations {
    padding: 20px 0;
    height: auto;
}

.notification {
    position: relative;
    align-self: center;
    margin: 0 auto;
    max-width: 90%;
    padding: 14px 14px;
    color: #000;
    border: 1px solid var(--brand-color-1);
    &::before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: var(--brand-color-1);
        opacity: 0.2;
        pointer-events: none;
    }
}