@import "../../assets/styles/base/colors";
@import "../../assets/styles/base/settings";

$charts-colors: #FF0000 rgb(66, 160, 66);

.users-statistics {
    display: block;
    text-align: center;
    .chart {
      &__main {
        position: relative;
        .invoices-counter{
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: nth($text-colors, 5);
          
          p{
            font-weight: bold;
            margin-bottom: 0;
            &.count{
              font-size: 40px;
              line-height: 1;
            }
            &.desc{
              font-size: 11px;
            }
          }
        }
        div:not(.invoices-counter){
          max-width: 100%;
          display: inline-block;
          width: 300px;
        }
      }
      &__legend {
        display: flex;
        flex-wrap: wrap;
        padding-left: 0;
        text-align: left;
        list-style: none;
        columns: 3;
        -webkit-columns: 3;
        -moz-columns: 3;
        margin-bottom: 0;
        justify-content: space-evenly;
        @media(max-width: $large-screen) {
          columns: 2;
          -webkit-columns: 2;
          -moz-columns: 2;
        }
        li {
          position: relative;
          display: flex;
          align-items: center;
          margin: 0 10px 10px 0;
          padding-left: 40px;
          height: 30px;
          @each $color in $charts-colors {
            $i: index($charts-colors, $color);
            &:nth-child(#{$i}):before{
              content: attr(data-value);
              text-align: center;
              color: #fff;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 30px;
              height: 30px;
              background: #{$color};
              position: absolute;
              left: 0;
              top: 0;
              border-radius: 50%;
              font-size: 12px;
              font-weight: bold;
            }
          }
        }
      }
    }
    svg{
      padding: 30px;
      height: 262px;
      overflow: visible;
      path{
        @each $color in $charts-colors {
          $i: index($charts-colors, $color);
          &:nth-child(#{$i}){
            stroke: #{$color} !important;
          }
        }
      }
    }
    .svg-top-header{
      font-size: 12px;
      font-weight: bold;
      fill: nth($text-colors, 4);
    }
    .svg-header{
      font-weight: normal;
      font-size: 6px;
    }
}