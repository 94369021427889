@import "../base/colors";
@import "../base/settings";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

$component-colors: #e4e4e5;

.ctl {
  .ctl__amount {
    display: flex;
    justify-content: flex-end;
    margin-right: 120px;
    font-weight: 600;
  }

  .ctl-footer, .ctl__footer{
    background: nth($component-colors, 1);
    padding: 25px 15px;
    width: 100%;

    .ctl-footer__row {
      display: flex;
      justify-content: space-between;
    }

    .ctl-footer__box{
        display: inline-flex;
        flex-direction: row;
        align-items: stretch;
        width: 100%;
        &.green{
            .icon{
                i{
                    color: nth($text-colors, 2);
                }
            }
            .box{
                background: nth($background-colors, 3);
                h3, h4, h5{
                    color: $white;
                }
            }
        }
        .icon{
            padding: 12px 10px 12px 0;
            width: 55px;
            text-align: right;
            i{
                font-size: 35px;
                color: nth($text-colors, 1);
            }
        }
        .box{
            background: $white;
            border-radius: 0 5px 5px 0;
            padding: 10px 15px;
            box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
            width: 100%;
            .ctl-footer__amounts{
                text-align: right;
                h3, h5{
                    display: inline-block;
                }
                h5{
                    font-weight: 300;
                    font-size: 14px;
                    color: nth($text-colors, 3);
                    text-transform: uppercase;
                    margin-left: 5px;
                    margin-bottom: 2px;
                }
            }
            h3{
                color: nth($text-colors, 2);
                font-size: 21px;
                margin-bottom: 2px;
                @media(max-width:$medium-screen)
                {
                    font-size: 18px;
                }
            }
            h4{
                color: nth($text-colors, 3);
                font-size: 14px;
                margin-bottom: 0;
            }
            h3, h4{
                font-weight: bold;
                text-align: right;
            }
        }
    }
}
}