$module-section-head-border-color: #cecece;
$module-section-head-title-color: #414042;

$module-section-filter-border-color: #e0e0e0;

$module-item-bg: #ffffff;
$module-item-shadow-color: #ddd;

.l-module {
  position: relative;
  margin: 0 -16px;
  &.l-module--primary {
    margin: 0;
    background: #fff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    .l-module__section {
      &.l-module__section--head {
        max-height: 53px;
        background: #fff;
        border-bottom: 1px solid #EBEDF2;
      }
    }
    .l-module__title {
      font-size: 14px;
      color: #414042;
    }
  }
  &.l-module--type-2 {
    .l-module__section {
      padding: 0 18px;
      &.l-module__section--head {
        background: none;
        border: none;
      }
    }
  }
  &--full-height {
    height: calc(100% - 16px);
    .l-module__section {
      height: 100%;
    }
  }
  &--loading {
    min-height: 60px;
  }
  .l-module__section {
    position: relative;
    padding: 0 18px;
    &.l-module__section--light {
      background: #fff;
    }
    &.l-module__section--loading {
      position: relative;
      min-height: 60px;
    }
    &.l-module__section--head {
      margin: 0;
      padding-top: 10px;
      padding-bottom: 10px;
      background:$module-section-title-bg;
      border-bottom: 1px solid $module-section-head-border-color;
      h1 {
        margin: 0;
        text-transform: uppercase;
        font-weight: 600;
        color: $module-section-head-title-color;
        font-size: 13px;
        i {
          padding: 0 4px 0 0;
        }
      }
    }
    &.l-module__section--filter {
      padding-top: 24px;
      padding-bottom: 24px;
      background: $module-section-filter-bg;
      border-bottom: 1px solid $module-section-filter-border-color;
    }
    &.l-module__section--p-0 {
      padding: 0;
    }
    > h1 {
      margin: 0 0 12px 0;
    }
  }
  .l-module__header {
    display: flex;
    align-items: center;
    padding: 10px 16px;
    background: $module-section-title-bg;
    border-bottom: 1px solid $module-section-head-border-color;
    &--transparent {
      background: transparent;
      border: none;
    }
  }
  .l-module__title {
    margin: 0;
    text-transform: uppercase;
    font-weight: 600;
    color: $module-section-head-title-color;
    font-size: 13px;
    i {
      padding: 0 4px 0 0;
    }
  }
  .l-module__title-highlighted {
    color: $brand-color-1;
    &::before {
      content: '';
      padding-left: 6px;
    }
  }
  .l-module__item {
    margin-bottom: $skin-padding * 2;
    &--fill {
      padding: 16px;
      background-color: $module-item-bg;
      box-shadow: 0 0 16px $module-item-shadow-color;
    }
    &--animated {
      opacity: 0;
      transform: translateY(20px);
      transition: opacity .5s ease, transform .5s ease;
    }
    &--fadein {
      opacity: 1;
      transform: translateY(0);
    }
    &:last-of-type {
      margin-bottom: 0;
    }
    &--no-padding-end {
      margin-right: -$skin-padding * 2;
      margin-left: -$skin-padding * 2;
    }
  }
  .l-module__footer {
    margin-top: $skin-padding * 2;
  }
}