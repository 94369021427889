@import "../../assets/styles/base/settings";
@import "../../assets/styles/base/colors";
@import "../../assets/styles/helpers/variables";
@import "../../assets/styles/helpers/mixins";

$root: l-debtor;

.#{$root} {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 12px;
  height: 100%;
  background: #fff;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);

  @include mq('min-width', 'xl') {
    flex-direction: column;
    flex-wrap: nowrap;
  }

  .#{$root}__main-details {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    height: 90%;
    justify-content: space-between;
  }

  .#{$root}__links {
    height: 10%;
    text-align: right;
  }

  .#{$root}__table {
    display: flex;
    flex-wrap: wrap;
  }

  .#{$root}__table-row {
    width: 100%;
    font-size: 12px;

    &--font-x2 {
      font-size: 14px;
    }

    @include mq('min-width', 'sm') {
      width: calc(95% /2);
      &:nth-child(2n+1) {
        padding-right: 10px;
      }
    }

    @include mq('min-width', 'md') {
      width: calc(95% / 2);
    }

    @include mq('min-width', 'xl') {
      width: 100%
    }
  }

  .#{$root}__summary {
    display: flex;
    width: 100%;

    @include mq('min-width', 'sm') {
      justify-content: flex-end;
    }

    @media (min-width: breakpoint(sm)) and (max-width: breakpoint(md)) {
      flex-basis: 100%;
    }

    @include mq('min-width', 'xl') {
      flex-basis: auto;
      width: auto;
    }
  }

  .#{$root}__summary-box {
    display: flex;
    width: 100%;

    @media (min-width: breakpoint(sm)) and (max-width: breakpoint(xl)) {
      width: 224px;
    }
  }
}


.debtor-details-module {
  .debtor-total-debt-tile {
    box-shadow: $default-box-shadow;
    flex-grow: 9;
    padding: 5px;
    text-align: right;
    margin: 18px 0;

    &__subtitle {
      font-size: 0.75rem;
      margin: 0;
    }

    .amount {
      font-size: 1.2rem;
      font-weight: bold;
      color: $grey1;
      vertical-align: middle;
    }

    .currency {
      vertical-align: sub;
    }
  }

  .dollar__label {
    font-size: 1.5rem;
    flex-grow: 1;

    @media screen and (max-width: $medium-screen) {
      display: none;
    }
  }

  .total-debt-row .col-12 {
      display: flex;
      align-items: center;
  }

  .row.data-row {
    padding: 4px 0;
    overflow-wrap: break-word;
  }

  .scoring {
    border-radius: 2px;
    height: 17px;
    width: 17px;
    
    &.red {
      background-color: nth($scoring-colors, 3);
    }
    &.yellow {
      background-color: nth($scoring-colors, 2);
    }
    &.green {
      background-color: nth($scoring-colors, 1);
    }
    &.unknown {
      background-color: nth($scoring-colors, 4);
    }
  }

  .debtor-name {
    &__label,
    &__value {
      font-weight: bold;
    }
  }

  .address__value {
    white-space: pre-wrap;
  }
}
