$border-color: rgba(175, 175, 175, 0.16);

.wrapper {
    position: relative;
    width: 100%;
    min-height: 30px;
}

.selectField {
    position: absolute;
    opacity: 0;
    visibility: hidden;
}

.selected {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    padding: 0 12px;
    font-size: 14px;
    width: 100%;
    height: 30px;
    background: #fff;
    border: none;
    border: 1px solid #E5E5E5;
    outline: none;
    cursor: pointer;
    &::after {
        content: "";
        position: absolute;
        right: 16px;
        display: inline-block;
        margin-left: 10px;
        vertical-align: 10px;
        border-top: 4px solid;
        border-right: 4px solid transparent;
        border-bottom: 0;
        border-left: 4px solid transparent;
        border-top-color: #000;
    }
    &.disabled {
        background: #f5f5f5;
        cursor: default;
        pointer-events: none;
    }
}

.list {
    position: absolute;
    z-index: 2;
    right: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-30px);
    list-style: none;
    margin: 30px 0 0 0;
    padding: 0;
    max-height: 200px;
    overflow-y: auto;
    background: #fbfbfb;
    box-shadow: 0 0 3px $border-color;
    transition: .3s ease opacity;
}

.listIsExpanded {
    opacity: 1;
    visibility: visible;
}

.listItem {
    padding: 10px 12px;
    font-size: 14px;
    border: 1px solid $border-color;
    cursor: pointer;
    word-break: break-word;
    transition: background .2s ease, color .2s ease;
    &:hover {
        background: darken(#fbfbfb, 6%);
    }
}

.listItemSelected {
    background: darken(#fbfbfb, 4%);
}

.directionTop {
    .list {
        bottom: 8px;
        margin: 0;
    }
}

.directionBottom {
    .list {
        top: 100%;
    }
}

.errorField {
    margin: 5px 0 0 0;
    color: #f00;
    font-size: 12px;
}