@import "./../base/colors";

$shadow-color: #ddd;

.tab {
  .tab__nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    border: none;
  }
  .tab__nav-item {
    margin: 0 3px;
    font-weight: 600;
    color: nth($text-colors, 6);
    background-color: nth($button-colors, 1);
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    box-shadow: 2px -1px 5px 0px $shadow-color;
    cursor: pointer;
    &--active {
      color: nth($text-colors, 2);
      background-color: #fff;
      box-shadow: 2px -5px 5px -3px $shadow-color;
    }
    &:last-child {
      margin-right: 0;
    }
    &:first-child {
      margin-left: 0;
    }
  }
  .tab__nav-link {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    width: 120px;
    height: 38px;
    font-size: 14px;
  }
  .tab__body {
    padding: 10px 20px;
    background-color: #fff;
    min-height: 400px;
    .module {
      box-shadow: none;

      .module-body {
        padding: 0;
      }
    }
  }
}
