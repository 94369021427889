@import "../../../assets/styles/base/colors";
@import "../../../assets/styles/base/settings";
@import "../../../assets/styles/helpers/variables";
@import "../../../assets/styles/helpers/mixins";

.main-sidebar{
    min-height: 100%;
    width: $leftMenuWidth;
    background-color: $grey2;
    .sidebar{
      height: auto;
      margin-top: 15px;
    }
    .dropdown-nav-link{
      .collapse, .collapsing{
        background: $grey1;
      }
    }
    @include mq('max-width', 'medium') {
      position: absolute;
      top: 60px;
      bottom: 0;
      left: 0;
      z-index: 830;
      transition: transform .3s ease-in-out, width .3s ease-in-out;
      transform: translate(-$leftMenuWidth, 0);
      &.opened {
        transform: translate(0px, 0);
      }
    }
  }