.case-timeline__main {
    position: relative;
    .case-timeline__v-scroll {
        position: absolute;
        z-index: 9;
        top: 1px;
        right: 1px;
        width: 16px;
        height: 190px;
        background: #efefef;
    }
}

.timeline-actions {
    display: flex;
    justify-content: flex-end;
    margin: 0 0 10px 0;
    button {
        color: #fff;
        background: var(--brand-color-1);
        transition: background .3s ease;
        &:hover {
            background: var(--btn-primary-bg-hover);
        }
    }
}

.timeline-v-scroll {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    button {
        color: darken(#efefef, 40%);
    }
    .timeline-v-scroll__bar {
        flex: 1;
        div {
            position: relative;
            width: 6px;
            background-color: darken(#efefef, 30%);
        }
    }
}