.wrapper {
    padding-left: 8px;
    padding-right: 8px;
    width: 100%;
    height: 30px;
    color: #495057;
    font-size: 14px;
    font-weight: 400;
    background-color: #fff;
    border: 1px solid #dedede;
    border-radius: 3px;
    &:disabled {
        background-color: #f3f3f3;
    }
}