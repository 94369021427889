.c-form-control {
  display: block;
  padding: .375rem .75rem;
  width: 100%;
  height: $input-height;
  color: #495057;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dedede;
  border-radius: 3px;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

  &:disabled {
    background-color: nth($background-colors, 5);
  }
}