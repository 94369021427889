.payment-row {
  display: flex;
  margin-top: 20px;
  &:first-of-type {
    margin-top: 10px;
  }

  &__sub {
    color: nth($text-colors, 3);
    margin-top: 2px;
    font-size: 10px;
    font-weight: 600;
    line-height: 1;
  }
  &__currency {
    font-weight: normal;
  }
  &__currency,
  &__description {
    color: $grey3;
    font-size: 13px;
    margin: 0;
  }
  &__tile {
    box-shadow: $default-box-shadow;
    flex-grow: 1;
    padding: 10px;
    text-align: right;
  }
  &__amount {
    font-size: 18px;
    font-weight: bold;
    line-height: 1;
    margin: 0;
  }
  &__category {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-right: 10px;
    width: 54px;
  }
  &__category-number {
    display: block;
    font-size: 20px;
  }
  &__unit {
    color: $grey3;
    display: block;
    font-size: 13px;
    font-weight: bold;
  }
}
