@import '../../assets/styles/base/colors';
@import '../../assets/styles/base/settings';
@import "../../assets/styles/helpers/variables";
@import "../../assets/styles/helpers/mixins";

.creditor-approval-waiting-container{
  margin-top: $min-margin;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  font-family: $default-font;

  .creditor-approval-waiting-window {
    box-shadow: $default-box-shadow;
    border-radius: 2px;
    color:$grey3;
    margin-left: auto;
    margin-right: auto;
    max-width: 500px;
    width: 100%;
    background-color: white;
    text-align: center;

    // @include mq('min-width', 'md') {
    //   position: absolute;
    //   top: 50%;
    //   left: 50%;
    //   transform: translate(-50%, -50%);
    // }

    .btn-home{
      margin: 40px auto;
      width: $button-max-width;
      height: $button-max-height;
      background-color: $brand-color-1;
      font-size: 14px;
      color:white;

      &:hover{
        background-color: $grey2;
      }
    }

    .creditor-approval-waiting-title{
           display: inline-block;
           margin-top:40px;
           font-weight: bold;
           font-size: 30px;
           line-height: 1;
           text-transform: uppercase;
         }

    .creditor-approval-waiting-content{
      display: inline-block;
      margin-top: 20px;
      font-size: 16px;
      line-height: 1.3;
      margin-left: 20px;
      margin-right: 20px;
      color: $grey2;
    }
  }
}

.creditors-pending-list {
  font-size: 16px;
  margin-top: 20px;

  .list-group-item {
    border: 0;
    padding: .5rem 1rem;
  }
}