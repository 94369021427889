@import "./colors";
@import "./settings";
@import "../helpers/variables";
@import "../helpers/mixins";

.modal-content{
    .modal-content--min-height{
        min-height: 150px;
    }
}

@media print{
    .print-content{
        padding: 60px;   
    }
}