.c-table {
  color: nth($font-colors, 1);

  &--no-width {
    width: unset;
  }
  
  &.c-table--col-2 {
    .c-table__row {
    display: flex;
    }
  }
  &.c-table--document {
    min-height: 43px;
  }
  thead {
    th {
      border-top: none;
    }
  }
  .c-table__row {
    padding: 0 0 8px 0;
  }
  .c-table__cell {
    line-height: 1.3;
    &:nth-of-type(1) {
      flex-basis: 40%;
    }
    &:nth-of-type(2) {
      flex-basis: 60%;
    }
    &.c-table__cell--actions {
      padding: 0;
      text-align: center;
    }
    .c-table__cell-btn {
      display: inline-block;
      padding: 10px;
      width: auto;
    }
    &--text-right {
      text-align: right;
    }

    &--padded-left {
      padding-left: 80px;
    }
  }
  .c-table__cell--text-bold{
    font-weight: bold;
  }
}

.table-loader {
  .table-loader__head {
    margin: 0 0 10px 0;
    height: 35px;
    background: #e1e1e2;
  }
  .table-loader__row {
    display: flex;
    justify-content: space-between;
    margin: 4px 0;
    padding: 10px 12px;
    height: 37px;
    background: #fff;
    div {
      width: calc(100% / 8);
      height: 100%;
      background: #e1e1e2;
      &:nth-of-type(1) {
        width: 60px;
        background-color: var(--brand-color-1);
      }
    }
  }
}