@import '../../../../assets/styles/base/colors';
@import '../../../../assets/styles/helpers/variables';
@import '../../../../assets/styles/helpers/mixins';

.wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 0;
    max-height: 380px;
    @include mq('min-width', 'lg') {
        height: 380px;
    }
    .list {
        flex: 1;
        margin: 0;
        padding: 12px 0;
        padding-right: 12px;
        list-style: none;
        overflow-y: auto;
        @include mq('max-width', 'lg') {
            padding-left: 12px;
        }
        &::-webkit-scrollbar {
            width: 4px;
        }
        &::-webkit-scrollbar-button {
            width: 0px;
            height: 0px;
        }
        &::-webkit-scrollbar-thumb {
            background: nth($scrollbar-colors, 2);
            border: 0px none $white;
            border-radius: 0px;
        }
        &::-webkit-scrollbar-track {
            background: nth($scrollbar-colors, 1);
            border: 0px none $white;
            border-radius: 0px;
        }
        &::-webkit-scrollbar-corner {
            background: transparent;
        }
    }
    
    .listItem {
        display: flex;
        margin-top: 12px;
        &:first-of-type {
            margin-top: 0;
        }
    }
    
    .sender {
        justify-content: flex-start;
        .message {
            background: #EBEBEB;
        }
    }
    
    .addressee {
        justify-content: flex-end;
        
        .message {
            color: #fff;
            background: $brand-color-1;
        }
        .messageAuthor {
            color: #fff;
        }
        .messageCreateDate {
            color: #fff;
        }
    }
    
    .message {
        padding: 12px 12px;
        max-width: 80%;
        border-radius: 3px;
    }
    
    .messageAuthor {
        color: #000;
        font-weight: 600;
        font-size: 14px;
    }
    
    .messageContent {
        margin: 0;
        font-size: 14px;
    }
    
    .messageCreateDate {
        float: right;
        padding: 10px 0 0 0;
        font-size: 12px;
        opacity: 0.6;
    }
    
    .textInputPanel {
        margin: 12px 0;
        padding-right: 12px;
        @include mq('max-width', 'lg') {
            padding-left: 12px;
        }
        input {
            padding: 12px;
            width: 100%;
            border: none;
            outline: none;
            background: #f5f5f5;
        }
    }
    
    .textInputNav {
        display: flex;
        margin: 12px 0 0 0;
    }
    
    .textInputNavGroup {
        i {
            cursor: pointer;
        }
    }
    
    .textInputNavGroupEnd {
        margin-left: auto;
    }
}
