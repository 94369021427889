@import "../../assets/styles/base/colors";

.new-user-box {
  background-color: $grey6;
  .card-title {
    text-transform: uppercase;
  }
}

.sign-in__card .card-icon.new-user-icon {
  background: url("NewUserIcon.png") no-repeat;
}

@media (min-width: 992px) {
  .new-user-box {
    height: 100%;
  }
  .registerButton {
    bottom: 20px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
  }
}
