@import "../../assets/styles/base/colors";
@import "../../assets/styles/base/settings";
@import "../../assets/styles/helpers/variables";
@import "../../assets/styles/helpers/mixins";

.invoice-details {
  display: flex;
  flex-wrap: wrap;
  background-color: white;
  padding: 12px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);

  .invoice-details__row {
    font-size: 12px;
    &--font-1 {
      font-size: 14px;
    }
  }
  
  .invoice-details__info {
    flex-grow: 1;
    flex-basis: 30%;

    @include mq('max-width', 'sm') {
      margin-bottom: 16px;
    }

    @media (min-width: breakpoint(md)) and (max-width: breakpoint(md)) {
      flex-basis: 60%;
      margin: 16px;
    }

    @include mq('min-width', 'lg') {
      flex-basis: 30%;
      margin-right: 12px;
    }
  }
  
  .invoice-details__payments {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding-left: 20px;
    flex-basis: 100%;

    @include mq('max-width', 'sm') {
      margin-bottom: 16px;
    }
        
    @media (min-width: breakpoint(sm)) and (max-width: breakpoint(md)) {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 16px;
      padding: 0;
    }

    @media (min-width: breakpoint(md)) and (max-width: breakpoint(lg)) {
      flex-basis: 40%;
    }

    @include mq('min-width', 'lg') {
      flex-basis: 16%;
      flex-direction: column;
      margin-right: 12px;
    }
  }

  .invoice-details__payments-box {
    margin: 0 0 18px 0;
    &:last-child {
      margin-bottom: 0;
    }

    @media (min-width: breakpoint(sm)) and (max-width: breakpoint(md)) {
      margin-left: 20px;
      width: calc(50% - 24px);
    }

    @media (min-width: breakpoint(md)) and (max-width: breakpoint(lg)) {
      flex-basis: 100%;
    }

    @include mq('min-width', 'lg') {
      margin-left: 0;
      width: 100%;
    }
  }

  .invoice-details__payments-box-amount {
    font-weight: 600;
    font-size: 16px;
  }

  .invoice-details__actions {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: space-between;

    @media (min-width: breakpoint(md)) and (max-width: breakpoint(lg)) {
      margin-top: 16px;
    }
    
    @include mq('min-width', 'lg') {
      flex-basis: 24%;
      max-width: 238px;
    }
  }

  .invoice-details__action-btn {
    flex-basis: 100%;
    margin-bottom: 16px;
    height: 46px;
    &:last-child {
      margin-bottom: 0;
    }

    @media (min-width: breakpoint(sm)) and (max-width: breakpoint(lg)) {
      flex-basis: 48%;
    }
  }
}

// OLD - strat
.invoice-details-module {
    .col-btns {
        .btn-default {
            margin: 5px 0;
            height: 47px;
            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }

            .fas, .far { font-size: 110%; }
        }

        padding: 5px 10px;
    }

    .currency {
        color: $grey3;
        vertical-align: sub;
    }

    .case-payment-tile {
        box-shadow: $default-box-shadow;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding: 5px;
        text-align: right;
        margin: 5px 0;
        overflow: hidden;

        &__subtitle {
            font-size: 0.75rem;
        }
    }

    .payment-tiles-col {
        .row {
            min-width: 150px;
        }
        .col-2 {
            padding: 0;
        }
    }

    .original {
        color: $grey1;
        font-size: 1.5rem;
        vertical-align: middle;
    }

    .payed {
        color: $brand-color-1;
        font-size: 1.5rem;
        vertical-align: middle;
    }

    .pending {
        color: $bright-red;
        font-size: 1.5rem;
        vertical-align: middle;
    }

    .interests {
        color: $blue2;
        font-size: 1.5rem;
        vertical-align: middle;
    }

    .amount {
        font-size: 1.2rem;
        font-weight: bold;
    }

    .dollar-label {
        margin: auto 0;
        padding: 0;

        @media screen and (max-width: $large-screen) {
            padding: 0 15px;
        }

        @media screen and (max-width: $medium-screen) {
            display: none;
        }
    }

    .row.data-row {
        padding: 4px 0;
        overflow-wrap: break-word;
    }

    .case-ref, .status, .account, .kid, .creditor, .invoices {
        &__label, &__value {
            font-weight: bold;
        }
    }

    .case-ref__value {
        color: $sea-green;
    }
}
// OLD - end