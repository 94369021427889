@import "../../../assets/styles/base/colors";
@import "../../../assets/styles/base/settings";
@import "../../../../node_modules/bootstrap/scss/functions";
@import "../../../../node_modules/bootstrap/scss/variables";
@import "../../../../node_modules/bootstrap/scss/mixins/breakpoints";

.error-container{
  margin-top:$min-margin;
  background: url("../../../assets/images/lighthouse404.svg") no-repeat;
  background-size: $error-background-width-number $error-background-height-number;
  background-position-x: 50%;
  font-family: $default-font;
  display: flex;
  flex-direction: column;
  flex:1;
  width:100%;

  @include media-breakpoint-down(md) {
    background: none;
  }
  
  @include media-breakpoint-up(xl) {
    background-size: $error-background-width-number*1.3 $error-background-height-number*1.3;
  }

  .error-window {
    box-shadow: $default-box-shadow;
    border-radius: 2px 2px 2px 2px;
    color:$grey3;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    padding: 40px 20px;
    max-width: 500px;
    width: 100%;
    background-color: white;
    text-align: center;

    .btn-home{
      margin: 40px auto;
      width: $button-max-width;
      height: $button-max-height;
      background-color: $brand-color-1;
      font-size: 14px;
      color:white;

      &:hover{
        background-color: $grey2;
      }
    }

    .error-window-title{
      display:inline-block;
      font-weight: bold;
      font-size: 30px;
      line-height: 1;
    }

    .error-window-content{
      display:inline-block;
      margin-top:20px;
      font-size:20px;
      line-height: 1;
      margin-left: 20px;
      margin-right: 20px;
      color:$grey2;
    }
  }
}
