$skin-padding: 8px;

$font-colors: #6d6b6f #00ab74 #49484b #e16652 #395667 #ffffff #0371b1;
$font-weights: 400 700;

$breakpoints: (
  'xs': 460px,
  'sm': 576px,
  'md': 768px,
  'lg': 992px,
  'xl': 1200px,
  'xxl': 1440px,
  'small': 576px,
  'medium': 768px,
  'large': 992px,
);
