@import "../../../assets/styles/base/colors";
@import "../../../assets/styles/base/settings";
@import "../../../assets/styles/helpers/variables";
@import "../../../assets/styles/helpers/mixins";

$info-card-icon-size: 80px;

.info-cards {
  display: flex;
  flex-wrap: wrap;
  @include mq('max-width', 'lg') {
    justify-content: space-between;
  }

  .info-cards__el {
    position: relative;
    display: flex;
    width: 180px;
    text-align: center;
    background-color: #fff;
    box-shadow: $default-box-shadow;
    border-radius: 2px;
    margin: 0 20px 10px 0;

    @include mq('max-width', 'xl') {
      margin: 0 10px 10px 0;
      width: calc(100% / 4 - 10px);
    }

    @include mq('max-width', 'lg') {
      margin: 0 0 10px 0;
      width: calc(100% / 3 - 10px);
    }

    @include mq('max-width', 'sm') {
      margin: 0 0 10px 0;
      width: calc(100% / 2 - 10px);
    }

    @include mq('max-width', 'xs') {
      margin: 0 0 10px 0;
      width: 100%;
    }

    &--with-redirect {
      cursor: pointer;
      &:hover {
        background-color: $grey4;
      }
    }

    &--link {
      color: inherit;
      &:hover {
        background-color: $grey7;
        transition: $transition;
      }
      &:hover,
      &:active {
        color: inherit;
        text-decoration: none;
        .info-cards__el-decor {
          width: 100%;
          transform: width;
        }
      }
      .info-card {
        height: calc(100% - 40px);
        @include mq("max-width", "xl") {
          height: calc(100% - 60px);
        }
      }
    }
  }

  .info-cards__el-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: $info-card-icon-size;
    height: $info-card-icon-size;
    color: white;
    text-shadow: $default-text-shadow;
    background-color: $grey6;
    box-shadow: $default-box-shadow;
  }

  .info-cards__el-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 1 auto;
  }

  .info-cards__el-counter {
    margin-bottom: 4px;
    margin-top: 10px;

    .info-cards__el-counter-primary-number {
      font-size: 20px;
      font-weight: bold;
    }
    .info-cards__el-counter-secondary-number {
      color: $grey3;
      font-size: 15px;
      &::before {
        content: "/";
      }
    }
  }
  .info-cards__el-title {
    color: $grey2a;
    font-size: 13px;
    font-weight: bold;
  }
}
