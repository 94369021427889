.c-color-picker {
    position: absolute;
    z-index: 2;
    .c-color-picker__cover {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
}