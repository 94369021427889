@import "../../assets/styles/base/settings";
@import "../../assets/styles/base/colors";
@import "../../assets/styles/helpers/variables";
@import "../../assets/styles/helpers/mixins";

.c-template-tag-list {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0 0 20px 0;
    list-style: none;
    li {
        position: relative;
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-start;
        margin: 0 20px 0 0;
        font-size: 14px;
        font-weight: 600;
        // @include mq('max-width', 'lg') {
        //     margin-bottom: 10px;;
        // }
        span {
            display: block;
            margin: 0 0 2px 0;
            padding: 0 0 2px 0;
            font-size: 12px;
            font-weight: 400;
            text-transform: uppercase;
            border-bottom: 1px solid #d6d6d6;
        }
    }
}