@import "../base/colors";

.intelecomchatstarter {
  border: 2px solid $brand-color-1;
  width: 60px;
  height: 60px;

  transition-property: all;
   transition-duration: 0.3s;

  &:hover {
    border: 2px solid $sea-green;
    transform: scale(1.2);
    margin-right: 8px;
  }
}
.intelecomchatheader,
.intelecomchat__button {
  background-color: $brand-color-1;
}

.intelecomchatdialog {
  height: 450px;
}
.intelecomchatstarter {
  z-index: 900;
  right: 0;
  bottom: 40px;
  margin-bottom: 0;
}
.msg_intro_variables {
  display: none;
}
