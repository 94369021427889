@import "buttons";

.stce {
  border-radius: 3px;
  display: flex;
  flex-direction: column;

  &__input-container {
    input {
      margin: 5px 0 5px 0;
    }
  }

  &__buttons-container {
    padding: 10px 0 5px;
    border-top: 1px solid #ddd;
  }
}

.stce-rich-editor {
  display: flex;
  flex-direction: column-reverse;

  &__toolbar {
    border-top: 1px solid #ddd;
    border-bottom: 0 !important;
  }

  &__button-group {
    vertical-align: top;
    margin: 0 5px 5px 0;
    white-space: nowrap;
    float: right;
  }

  & div:first-child .c-btn--1 {
    @extend .c-btn--1;
  }
}
