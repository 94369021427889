@import "../../assets/styles/base/colors";

.iddp {
  .iddp__action-buttons {
    margin-top: 10px;
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-end;
    button[type="button"] {
      margin: 0 5px;
      padding: 5px 30px;
      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .react-bootstrap-table-editing-cell input {
    max-width: 50px;
    max-height: 17px;
  }

  .iddp__labels-col {
    .row {
      padding: 10px 0;
      .col-6 {
        display: flex;
        align-items: center;
        span {
          display: flex;
          word-break: break-all;
        }
        &:first-child {
          justify-content: flex-end;
        }
        &:last-child {
          justify-content: flex-start;
        }
      }
    }
  }

  &__payment-amount {
      min-width: 140px;
  }

  &__validation-error {
    color: $bright-red;
  }

  ul {
    margin: 0;
  }

  &__validation-errors-summary {
    margin: 10px 0;
  }
}

.iddp__table-cell--editable::after {
  content: '\f303';
  font-weight: 600;
  top: 0;
  left: 0;
  margin-left: 5px;
  font-family: "Font Awesome 5 Free"
}
