@import "../../assets/styles/base/colors";
@import "../../assets/styles/base/settings";

$transition: transform .3s ease-in-out,margin .3s ease-in-out;

.messages-footer{
  .sticky-footer{
    margin-top: -55px;
  }
}
.sticky-footer {
  background-color: $grey3;
  transition: $transition;
  bottom: 0;
  color: white;
  height: $footer-height;
  line-height: $footer-height;
  z-index: 820;
  width: 100%;
  .sticky-footer__body {
    margin: 10px 10px;
    background: url("../../assets/images/concentlogo.svg") no-repeat right center;
    background-size: auto 100%;
    width: 180px;
    height: 35px;
  }
}
