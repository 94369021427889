.l-section  {
    margin: 0 0 40px 0;
    &.l-section--decor {
      padding: 20px 40px;
      background: #747474;
      @include mq('max-width', 'sm') {
        padding-bottom: 10px;
      }
      .l-section__header {
        margin: 0 0 10px 0;
        border-color: #fff;
        @include mq('max-width', 'sm') {
          padding-bottom: 10px;
        }
        h1 {
          color: #fff;
          text-transform: none; 
          font-size: 24px;
        }
      }
    }
    .l-section__header {
      margin: 0 0 28px 0;
      border-bottom: 1px solid #A8A8A8;
      @include mq('max-width', 'sm') {
        margin-bottom: 10px;
      }
      h1 {
        margin: 0 0 10px 0;
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
      }
    }
  }