.checkbox {
    margin: 0 -10px;
    display: flex;
    
    input {
        visibility: hidden;
        position: absolute;
    }
}

.checkboxInput {
    &--secondary {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 10px;
        text-align: center;;
        color: #ABABAB;
        border: 1px solid #D4D4D4;
        i {
            opacity: 0;
            margin: 0 0 10px 0;
        }
        label {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0 20px;
            width: 100px;
            height: 90px;
            cursor: pointer;
        }
    }
}

.checkboxInputChecked {
    color: #28A745;
    border-color: #28A745;
    i {
        opacity: 1;
    }
}