@import '../../assets/styles/base/colors';

.withdraw-case {
    .action-buttons {
        margin-top: 20px;
        display: flex;
        justify-content: flex-end;

        button {
            margin: 0 10px;
            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }
    .validation-errors {
        color: $bright-red;
    }
}