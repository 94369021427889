.c-cashflow {
  padding: 0 0 20px 0;
  width: 100%;
  background-color: #fff;
  .c-cashflow__legend {
    padding: 18px;
    height: 100%;
    background-color: #eaeaea;
  }
  .c-cashflow__legend-title {
    font-weight: 600;
  }
}

.c-cashflow-list {
  position: relative;
  margin: 0;
  padding: 0 0 0 14px;
  list-style: none;
  .c-cashflow-list__item {
    &::before {
      content: "";
      position: absolute;
      top: 4px;
      left: 0;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #000;
    }
    &--color-1 {
      &::before {
        background-color: var(--brand-color-1);
      }
    }
  }
}
