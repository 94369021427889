.c-widget {
    &--light {
      color: #fff;
    }
    .c-widget__item {
      display: flex;
      flex-direction: column;
      @include mq('max-width', 'sm') {
        margin-bottom: 30px;
      }
      &--center {
        align-items: center;
        .c-widget__item-title {
          text-align: center;
        }
      }
      &--br {
        &::before {
          content: '';
          position: absolute;
          right: 0;
          height: 100%;
          width: 1px;
          border-right: 1px solid #DEDEDE;
        }
      }
    }
    .c-widget__item-title {
      position: relative;
      margin: 0 0 18px 0;
      font-size: 14px;
      text-transform: uppercase;
      @include mq('max-width', 'sm') {
        margin-bottom: 8px;
      }

      &--no-margin {
        margin: 0;
      }
    }
    .c-widget__item-value {
      line-height: 1.3;
      font-size: 26px;
      font-weight: 600;

      &--small {
        font-size: 22px;
      }

    }
    .c-widget__item-link {
      display: flex;
      justify-content: flex-end;

      &--flex-end {
        align-self: flex-end;
      }
    }
  }

.c-widget-list {
  list-style: none;
  margin: 0;
  padding: 0;
  .c-widget-list__item {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #e8e8e8;
    &:last-child {
      border: none;
    }
    span {
      font-weight: bold;
    }
  }
}