@import "./colors";

.react-bootstrap-table-pagination {
  margin: 10px 0;
  .pagination {
    float: right;
    margin: 0;
    .page-item {
      &:first-child {
        .page-link {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
      &:last-child {
        .page-link {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
      a {
        color: nth($text-colors, 1);
        transition: $transition;
        &:hover {
          background: nth($background-colors, 4);
          color: nth($text-colors, 6);
        }
      }
      &.active {
        a {
          background-color: nth($background-colors, 3);
          border-color: nth($background-colors, 3);
          color: nth($text-colors, 6);
        }
      }
    }
  }
  .dropdown-menu {
    margin: 0;
    padding: 0;
    width: 100%;
    min-width: auto;
    border-radius: 0;
    border: 1px solid $input-border-color;
    li {
      padding: 0;
      a {
        color: nth($text-colors, 1);
        padding: 5px 15px;
        display: block;
        transition: $transition;
        &:hover {
          text-decoration: none;
        }
      }
      &:hover {
        background: nth($background-colors, 3);
        a {
          color: nth($text-colors, 6);
        }
      }
    }
  }
  .react-bs-table-sizePerPage-dropdown {
    display: inline-block;
    &.show {
      button {
        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
    }
    button {
      background: nth($background-colors, 4);
      border-radius: 0;
      border: none;
      &:hover {
        background: nth($background-colors, 3);
      }
      &:focus {
        outline: none;
        box-shadow: none;
      }
      &:not(:disabled):not(.disabled):active:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }
}
