@import "../../assets/styles/base/colors";
@import "../../assets/styles/base/settings";
@import "../../assets/styles/helpers/variables";
@import "../../assets/styles/helpers/mixins";

$icon-colors: #00ac6e #395667 #029ed9 #ff8d1c #e16652 #7aa8c1;

.case-statictics {
    width: 100%;
    .case-summary-box {
        display: inline-flex;
        flex-direction: row;
        align-items: stretch;
        width: 100%;
        height: 100%;
        @include mq('max-width', 'xl') {
            height: calc(100% - 10px);
            margin-bottom: 10px;
        }
        .icon {
            @media(max-width: $medium-screen) {
                padding: 8px 15px 8px;
            }
            padding: 14px 15px 8px;
            width: 55px;
            text-align: right;
            i {
                font-size: 30px;
                @each $color in $icon-colors {
                    $i: index($icon-colors, $color);
                    &.color-#{$i}{
                        color: nth($icon-colors, $i);
                    }
                }
            }
        }
        .box {
            @media(max-width: $medium-screen) {
                padding: 4px 10px;
            }
            background: $white;            
            padding: 7px 10px;
            box-shadow: $box-shadow;
            width: 100%;
            .case-sumary-amounts {
                text-align: right;
                h3, h5 {
                    display: inline-block;
                }
                h5 {
                    font-weight: 300;
                    font-size: 14px;
                    color: nth($text-colors, 3);
                    text-transform: uppercase;
                    margin-left: 5px;
                    margin-bottom: 2px;
                }
            }
            h3 {
                color: nth($text-colors, 2);
                font-size: 18px;
                margin-bottom: 2px;
                @media(max-width:$large-screen) {
                    font-size: 16px;
                }
            }
            h4 {
                color: nth($text-colors, 3);
                font-size: 13px;
                margin-bottom: 0;
            }
            h3, h4 {
                font-weight: bold;
                text-align: right;
            }
        }
    }
}
