@import "../../assets/styles/base/colors";

div.invoice-payments {
  & table.table {
    background-color: transparent;
    width: unset;
    & thead th {
      border-top: 0;
      width: 160px;
    }
    & tr {
      td {
        border: unset !important;
      }
      &:not(:last-child) {
        border-bottom: 1px solid $border-color;
        & td {
          border: unset !important;
        }
      }
    }
  }
}
