@import "../../assets/styles/base/colors";

.cashflow-form {
  justify-content: flex-end;
}

.chart-container {
  width: 70%;
  margin: 0 40px 40px 40px;
}

.legend li {
  padding-left: 20px;
  position: relative;
  &::before {
    border-radius: 50%;
    content: '';
    display: block;
    height: 10px;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
  }
  &:first-child::before {
    background-color: $brand-color-1;
  }
  &:nth-child(2)::before {
    background-color: $blue2;
  }
  &:nth-child(3)::before {
    border: 1px solid $red1;
  }
  &:nth-child(4)::before {
    border: 1px solid $orange1;
  }
  &:nth-child(5)::before {
    border: 1px solid $blue4;
  }
  &:nth-child(6)::before {
    border: 1px solid $indigo;
  }
}
