.c-creator {
  // max-width: 698px;
  .c-creator__box-title {
    margin-bottom: 1rem;
    font-size: 14px;
    font-weight: 600;
  }
  .c-creator__body {
    position: relative;
    min-height: 100px;
  }
  .c-creator__box {
    margin: 20px 0 0 0;
    padding: 20px 0 0 0;
    min-height: 50px;
    border-top: 2px solid #efefef;
    &:first-child {
      margin: 0;
      padding: 0;
      border-top: none;
    }
  }
  .c-creator__footer {
    position: relative;
    margin: 20px 0 0 0;
    padding: 20px 0 0 0;
    border-top: 1px solid #E4E4E4;
    // margin: 12px 0;
    // padding: 12px 0;
    overflow: hidden;
    // border-top: 1px solid #eaeaeb;
    &::before {
      content: '';
      position: absolute;
      top: 0px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #ececec;
    }
    &--btn-action {
      display: flex;
      justify-content: space-between;
    }
    &--right {
      justify-content: flex-end;
    }
    .c-creator__footer-btn {
      float: right;
    }
  }
}

.c-creator__overview {
  display: flex;
  // flex-direction: column;
}

.c-creator__overview-tile {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  margin-bottom: 12px;
  width: 72px;
  height: 62px;
  color: nth($text-colors, 6);
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  background-color: #49484b;
  border-radius: 4px;
}

.c-creator__overview-desc {
  flex: 1;
  p {
    margin: 0 0 5px 0;
  }
  .pr-10 {
    padding-right: 10px;
  }
}

.c-creator__overview-desc-title {
  margin: 0;
  margin-bottom: 14px;
  padding-left: 8px;
  color: #49484b;
  font-size: 14px;
  font-weight: 600;

}

.c-creator__overview-desc-list {
  margin: 0;
  padding: 0;
  list-style: none;
  .date {
    display: flex;
    justify-content: space-between;
  }
}
