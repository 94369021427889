@import '../../assets/styles/base/colors';

h6 {
    &.create-case-header{
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 15px;
        &.bordered-bottom{
            border-bottom: 1px solid nth($borders-colors, 2);
            padding-bottom: 8px;
        }
    }
}