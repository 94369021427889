@import "../../assets/styles/base/colors";
@import "../../assets/styles/base/settings";
@import "../../assets/styles/helpers/variables";
@import "../../assets/styles/helpers/mixins";

$component-colors: #d2f3e6 #6b6d6f #49484b #e16652;
$message-colors: #929597 #00ac6e;

$headerHeight: 60px;
$footerHeight: 55px;
$logoBarMobileHeight: 60px;

$moduleTitleSectionHeight: 36px;

$messagesMarginTop: 20px;
$messageFilterHeihgt: 38px;

$conversationListHeight: calc(100vh - (#{$headerHeight} + #{$moduleTitleSectionHeight} + #{$messagesMarginTop} + #{$messageFilterHeihgt} + #{$footerHeight} + 37px));

$messageListHeight: calc(100vh - (#{$headerHeight} + #{$moduleTitleSectionHeight} + 130px + 147px + #{$footerHeight}));

$rowBorderTransparent: 2px solid transparent;
$rowBorderActive: 2px solid nth($component-colors, 2);
$rowBorderHover: 2px solid nth($message-colors, 2);
$conversationsHeadlineHeight: calc(27px + 38px + 16px + 41px);
$conversationStaticHeight: $navbar-height + $footer-height + ($skin-padding * 2);

.m-messages {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 -16px;
  .btn-conversation-back {
    display: none;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    width: 30px;
    height: 30px;
    color: $brand-color-1;
    font-size: 16px;
    background: #fff;
    border-radius: 50%;
    border: 1px solid $input-border-color;
    transform: all 0.3s ease;
  }
  .m-conversations__sort {
    width: 100%;
    padding-top: 10px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 13px;
    color: #414042;
    &--type-2 {
      display: flex;
      align-items: stretch;
      justify-content: center;
      margin-bottom: 5px;
      width: 100%;
      color: #414042;
      font-weight: 700;
      font-size: 13px;
      text-align: center;
    }
    p {
      margin: 0;
    }
    .m-conversations__sort-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 5px;
      padding: 8px 12px;
      border-radius: 5px;
      border: 1px solid #dee2e6;
      text-transform: none;
      cursor: pointer;
      transition: all 0.3s ease;
      &.active {
        padding-right: 24px;
        background: #fff;
      }
      i {
        transform: translateX(8px);
      }
    }
  }
  .m-conversations__list-header {
    display: flex;
    align-items: center;
  }
  .m-conversations__list-body {
    display: flex;
    flex-direction: column;
    padding-top: 5px;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 167ms;
  }

  .m-conversations {
    margin-top: 20px;
    &.m-conversations--in-tab {
      margin-top: 0;
      .m-conversations__list {
        .conversations-list {
          max-height: 510px;
        }
      }
    }
    &.c-view {
      min-height: auto;
    }
    .m-conversations__filters {
      height: $messageFilterHeihgt;
      .conversations-search {
        background: $white;
        border: 1px solid $input-border-color;
        color: $grey3;
      }
    }
    .m-conversations__list {
      overflow-y: hidden;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 167ms;
      .conversations-list {
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        height: $conversationListHeight;
        @include mq("max-width", "lg") {
          height: calc(#{$conversationListHeight} - 28px);
        }
        @include mq("max-width", "md") {
          height: calc(#{$conversationListHeight} - 105px);
        }
        &::-webkit-scrollbar {
          @media (max-width: $medium-screen) {
            width: 4px;
          }
          width: 10px;
        }
        &::-webkit-scrollbar-button {
          width: 0px;
          height: 0px;
        }
        &::-webkit-scrollbar-thumb {
          background: nth($scrollbar-colors, 2);
          border: 0px none $white;
          border-radius: 3px;
        }
        &::-webkit-scrollbar-track {
          background: nth($scrollbar-colors, 1);
          border: 0px none $white;
          border-radius: 3px;
        }
        &::-webkit-scrollbar-corner {
          background: transparent;
        }
        .conversations-list-content {
          padding: 0 5px 0 0;
          list-style: none;
          li {
            background-color: $white;
            border: $rowBorderTransparent;
            cursor: pointer;
            min-width: 100%;
            transition: 0.3s all ease;
            border-radius: 5px;
            margin-bottom: 8px;
            padding: 5px 0px;
            font-size: 12px;
            text-overflow: ellipsis;
            -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
            -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
            box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
            &:hover {
              border: $rowBorderHover;
            }
            label {
              &.light {
                color: nth($message-colors, 1);
                margin-bottom: 0;
              }
              &.nowrappable {
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-bottom: 0;
              }
            }
            &.answered {
              opacity: 0.9;
              color: #949494;
              background-color: #dadada;
            }
          }
          .answered-icon {
            position: absolute;
            bottom: 0;
            right: 6px;
            font-size: 16px;
          }
          .date-overdue {
            color: nth($component-colors, 4);
          }
        }
      }
    }

    .m-conversations__footer {
      display: flex;
      justify-content: center;
      margin: 10px 0 0 0;
      width: 100%;
    }
  }

  .m-conversation-messages {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    min-height: 500px;
    &--full-height {
      margin-right: -$skin-padding;
    }
    &--in-tab {
      .m-messages-list {
        max-height: 510px;
      }
    }
  }

  .modal-content {
    .new-message-modal-body {
      .new-message-footer {
        .btn.send-message-button,
        label.attachment-button {
          color: unset;
        }
      }
      .new-message-form-container {
        padding: 0;
        color: unset;
        background-color: unset;
      }
    }
  }

  .m-conversation-messages,
  .new-message-modal-body {
    .m-messages-list {
      height: $messageListHeight;
      overflow-y: auto;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 167ms;
      -webkit-overflow-scrolling: touch;
      @include mq("max-width", "lg") {
        height: calc(#{$messageListHeight} - 20px);
      }
      @include mq("max-width", "md") {
        height: calc(#{$messageListHeight} - 80px);
      }
      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-button {
        width: 0px;
        height: 0px;
      }
      &::-webkit-scrollbar-thumb {
        background: nth($scrollbar-colors, 2);
        border: 0px none $white;
        border-radius: 0px;
      }
      &::-webkit-scrollbar-track {
        background: nth($scrollbar-colors, 1);
        border: 0px none $white;
        border-radius: 0px;
      }
      &::-webkit-scrollbar-corner {
        background: transparent;
      }
      ul {
        &.messages-list-content {
          min-height: auto;
          margin-top: auto;
          padding: 10px 8px;
          list-style: none;
          height: 100%;
          width: 100%;
          margin-bottom: 0;
          li {
            margin: 0 0 10px;
            font-size: 13px;
            .message-item {
              box-sizing: border-box;
              position: relative;
              padding: 0;
              white-space: pre-line;
              width: 100%;
              &.not-send {
                .message-content {
                  opacity: 0.6;
                }
              }
              .message-content {
                display: block;
                padding: 12px 15px;
                border-radius: 5px;
                font-size: 13px;
                box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15);
                width: 90%;
                &.received {
                  background-color: nth($component-colors, 1);
                  margin-right: auto;
                }
                &.send {
                  background-color: rgb(255, 255, 255);
                  margin-left: auto;
                }
                .message-sender-details {
                  font-weight: bold;
                }
                .date {
                  text-align: right;
                  font-size: 11px;
                  color: nth($message-colors, 1);
                }
              }
            }
            &.message-action-active {
              position: relative;
              .message-content {
                margin-right: 22px;
              }
              .spinner-border,
              .error-icon {
                position: absolute;
                right: 0;
              }
              .spinner-border {
                width: 0.8rem;
                height: 0.8rem;
                top: calc(50% - 7px);
                border-width: 0.16em;
              }
              .error-icon {
                color: $bright-red;
                top: calc(50% - 16px);
              }
              .error-link {
                width: 100%;
                text-align: right;
                display: inline-block;
                clear: both;
                margin-top: 5px;
                font-size: 12px;
                color: $bright-red;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
    .new-message-form-container {
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 167ms;
      position: static;
      border: none;
      transition-property: all;
      flex: 0 0 auto;
      display: flex;
      flex-direction: column;
      width: 100%;

      background: nth($component-colors, 2);
      color: nth($text-colors, 6);
      padding: 20px;

      &--light {
        background: white;
        color: nth($text-colors, 2);
        
        .new-message-footer {
          label.attachment-button {
            background: unset;
            color: unset;

            &:hover {
              color: nth($text-colors, 1);
            }
          }
        }

        .attached-files {
          li {
            button.trash-button {
              background: unset;
              color: unset;

              &:hover {
                color: nth($text-colors, 1);
              }
            }
          }
        }
      }

      .new-message-text-editor {
        position: relative;
        flex-grow: 1;
        display: flex;
        textarea {
          min-height: 40px;
          overflow: auto;
          border-top: 2px solid rgba(0, 0, 0, 0.1);
          padding: 12px 48px 0 12px;
          max-height: 150px;
          flex-grow: 1;
        }
      }
      .new-message-footer {
        .send-message-button,
        .attachment-button {
          border-radius: 50%;
          padding: 0;
          border: none;
          color: nth($text-colors, 6);
          &:hover {
            color: nth($component-colors, 1);
          }
        }
        .send-message-button {
          padding: 2px 10px;
          font-size: 20px;
          border: 1px solid $input-border-color;
          border-radius: 0;
          font-size: 13px;
          text-transform: none;
          color: nth($text-colors, 6);
          margin-left: 10px;
          transition: 0.3 all ease;
          &:hover {
            background: nth($message-colors, 2);
          }
          &:focus {
            outline: 0;
            box-shadow: none;
          }
        }
        .attachment-button {
          background: none;
        }
      }
      .attached-files {
        padding-left: 0;
        list-style: none;
        margin-bottom: 0;
        li {
          padding-right: 4px;
          padding-bottom: 2px;
          display: inline-block;
          width: 33.333333%;
          position: relative;
          .attachment-container {
            padding: 5px 8px 2px;
            font-size: 12px;
            border: 1px solid $border-color;
          }
          .filename {
            margin-right: 5px;
            display: inline-block;
            max-width: 65%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .size {
            font-size: 9px;
            display: inline-block;
            overflow: hidden;
          }
          .trash-button {
            position: absolute;
            right: 10px;
            background: none;
            color: nth($text-colors, 6);
            border: 0;
            padding: 0;
            cursor: pointer;
          }
        }
      }
    }
    .debtor-details-container {
      padding: 12px 15px 12px 15px;
      color: nth($text-colors, 6);
      background: nth($component-colors, 2);
      font-size: 13px;
      height: 130px;
      overflow: hidden;
      dt {
        font-weight: normal;
      }
      dd {
        margin-bottom: 0.25rem;
        font-weight: bold;
      }
    }
  }

  &.m-messages--mobile {
    margin: 0;
    .btn-conversation-back {
      display: none;
    }
    &.non-active-hidden {
      display: none;
    }
    &.c-view {
      .btn-conversation-back {
        display: inline-flex;
      }
      .m-conversations {
        display: none;
      }
    }

    
    .m-conversations__sort {
      &--type-1,
      &--type-2 {
        display: none;
      }
    }

    .m-conversations__list {
      .conversations-list {
        height: 422px;
        &::-webkit-scrollbar {
          width: 4px;
        }
      }
    }

    .m-conversation-messages {
      min-height: initial;
      margin: 0;
      margin-right: -$skin-padding;
      margin-left: -$skin-padding * 2;
      &.non-active-hidden {
        display: none;
      }
      .m-messages-list {
        height: 218px;
      }
    }

    .m-conversation-messages,
    .new-message-modal-body {
      .m-messages-list {
        &::-webkit-scrollbar {
          width: 4px;
        }
      }
      .new-message-form-container {
        .attached-files {
          li {
            width: 50%;
          }
        }
      }
    }
  }

  &.m-messages--standard {
    @media (max-width: $large-screen - 1) {
      .btn-conversation-back {
        display: none;
      }
      &.non-active-hidden {
        display: none;
      }
      &.c-view {
        .btn-conversation-back {
          display: inline-flex;
        }
        .m-conversations {
          display: none;
        }
      }
    }

    .m-conversations__sort {
      &--type-1 {
        @include mq("max-width", "lg") {
          display: none;
        }
      }
      &--type-2 {
        @include mq("min-width", "lg") {
          display: none;
        }
      }
    }
    .m-conversations__list-header {
      @include mq("min-width", "lg") {
        margin: 0 4px;
        padding: 0 5px 0 0;
      }
    }

    .m-conversations {
      .m-conversations__list {
        .conversations-list {
          &::-webkit-scrollbar {
            @media (max-width: $medium-screen) {
              width: 8px;
            }
            width: 8px;
          }
          .conversations-list-content {
            li {
              @media (min-width: $large-screen) {
                &.active {
                  background-color: nth($component-colors, 2);
                  border: $rowBorderActive;
                  color: nth($text-colors, 6);
                  .date-overdue {
                    color: nth($text-colors, 6);
                  }
                }
              }
            }
          }
        }
      }
    }

    .m-conversation-messages {
      @media (max-width: $large-screen) {
        &.non-active-hidden {
          display: none;
        }
      }
    }

    .m-conversation-messages,
    .new-message-modal-body {
      .m-messages-list {
        &::-webkit-scrollbar {
          @media (max-width: $medium-screen) {
            width: 1px;
          }
          width: 4px;
        }
      }
      .new-message-form-container {
        .attached-files {
          li {
            @media (max-width: $small-screen) {
              width: 50%;
            }
          }
        }
      }
    }
  }
}
