.c-flag {
  display: inline-block;
  &--da-dk {
    width: 22px;
    height: 16px;
    background-image: url("../../images/flags.png");
    background-position: 0 0px;
  }
  &--en-us {
    width: 22px;
    height: 16px;
    background-image: url("../../images/flags.png");
    background-position: 0 -16px;
  }
  &--nb-no {
    width: 22px;
    height: 16px;
    background-image: url("../../images/flags.png");
    background-position: 0 -32px;
  }
  &--sv-se {
    width: 22px;
    height: 16px;
    background-image: url("../../images/flags.png");
    background-position: 0 -48px;
  }
}
