.c-list {
    &--primary {
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0;
        list-style: none;;
        .c-list__item {
            position: relative;
            display: block;
            padding: 16px 20px;
            padding-right: 40px;
            overflow-wrap: break-word;
            border-bottom: 1px solid #E2E2E2;
            &::before {
                position: absolute;
                right: 20px;
                top: 50%;
                transform: translateY(-50%);
                content: "\f054";
                font-weight: 900;
                font-family: "Font Awesome 5 Free";
                display: inline-block;
                font-style: normal;
                font-feature-settings: normal;
                font-variant: normal;
                text-rendering: auto;
                line-height: 1;
            }
            &:last-child {
                border-bottom: none;
            }
            &--pointer {
                cursor: pointer;
            }
            &--active {
                color: #28A745;
                font-weight: 600;
                background: #CBE9D2;
            }
        }
    }
}


.c-list--ddn-details {
    padding: 0;
    list-style: none;
    li {
        position: relative;
        margin-bottom: 8px;
        padding-left: 8px;
        span {
            position: absolute;
            left: 140px;
            font-weight: 600;
            word-break: break-all;
        }
    }
}

.c-list--2 {
    margin: 0;
    padding: 0;
    list-style: none;
    .c-list__item {
        margin: 0 0 4px 0;
        padding: 8px 10px;
        font-size: 13px;
        border: 1px solid #e4e4e4;
    }
}

.c-list--3 {
    margin: 10px 0 0 0;
    padding: 0;
    list-style: none;
    li {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        margin: 0 0 6px 0;
        width: 100%;
        font-size: 13px;
        font-weight: 600;
    }
    .c-list__item--decor {
        flex-basis: 60%;
        max-width: 600%;
        padding: 4px 10px;
        font-weight: 400;
        border: 1px solid #e4e4e4;
        span {
            display: inline-block;
            padding-right: 5px;
        }
    }
}
