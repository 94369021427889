$width: 34px;
$padding: 5px;
$height: 22px;

.c-switch-input {
    cursor: pointer;
    background: #fff;
    padding: $padding;
    width: $width;
    height: $height;
    border-radius: $height / 2;
    border: 1px solid #D8D8D8;
    input {
        position: absolute;
        opacity: 0;
        width: 0;
        height: 0;
        &:checked {
            + .c-switch-input__switch {
                grid-template-columns: 1fr 1fr 0fr;
                &::after {
                  background-color: $brand-color-1;
                }
            }
        }
    }
    &__switch {
        height: 100%;
        display: grid;
        grid-template-columns: 0fr 1fr 1fr;
        transition: .2s;
        //ICYMI, pseudo elements are treated as grid items
        &::after {
          content: '';
          border-radius: 50%;
          background: #ccc;
          grid-column: 2;
          width: 10px;
          transition: background .2s;
        }
    }
}
