.select {
    position: relative;
    select {
        position: absolute;
        visibility: hidden;
        opacity: 0;
    }
}

.selectedValue {
    position: relative;
    margin: 0;
    padding: 0 36px 0 8px;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    // background-color: #fbfbfb;
    border: 1px solid #dedede;
    border-radius: 3px;
    user-select: none;
    cursor: pointer;
    &::before {
        content: "";
        position: absolute;
        top: 12px;
        right: 10px;
        width: 0;
        height: 0;
        border: 6px solid transparent;
        border-top-color: #a0a0a0;
    }
}

.selectedValueDisabled {
    cursor: default;
    background: #ececec;
}

.selectedValueIsOpen {
    &::before {
        transform-origin: 6px 3px;
        transform: rotate(180deg);
    }
}

.selectList {
    position: absolute;
    z-index: 1;
    top: 100%;
    right: 0;
    left: 0;
    visibility: hidden;
    opacity: 0;
    margin: 0;
    padding: 0;
    max-height: 235px;
    overflow-y: auto;
    list-style: none;
    border: 1px solid #e5e0df;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    user-select: none;
    transform: translateY(-10px);
    transition: transform .2s ease-out, opacity .3s ease;
}

.selectListIsOpen {
    visibility: visible;
    opacity: 1;
    transform: translateY(-1px);
}

.selectListItem {
    padding: 6px 8px;
    line-height: 1.3;
    font-size: 14px;
    background-color: #fff;
    border-bottom: 1px solid #e5e0df;
    cursor: pointer;
    transition: background .3s ease;
    &:hover {
        background: #efefef;
    }
    &:last-child {
        border: none;
    }
}

.selectListItemSelected {
    color: #28A745;
    background-color: #CBE9D2;
    &:hover {
        background-color: #CBE9D2;
    }
}