@import "../../assets/styles/base/colors";
@import "../../assets/styles/base/settings";

.chart-portfolio {
    display: block;
    text-align: center;
    min-height: 270px;
    .chart{
      div{
        max-width: 100%;
        display: inline-block;
        width: 300px;
      }
    }
    svg{
      padding: 30px;
      height: 230px;
      overflow: visible;
      // path{
      //   @each $color in $charts-colors {
      //     $i: index($charts-colors, $color);
      //     &:nth-child(#{$i}){
      //       stroke: #{$color} !important;
      //     }
      //   }
      // }
    }
    .chart-legend {
      display: flex;
      flex-wrap: wrap;
      padding-left: 0;
      text-align: left;
      list-style: none;
      columns: 3;
      -webkit-columns: 3;
      -moz-columns: 3;
      margin-bottom: 0;
      @media(max-width: $large-screen) {
        columns: 2;
        -webkit-columns: 2;
        -moz-columns: 2;
      }
      li {
        position: relative;
        display: flex;
        align-items: center;
        margin: 0 10px 10px 0;
        height: 30px;
        width: calc(100% / 2 - 10px);
        span {
          margin-right: 5px;
          text-align: center;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 34px;
          height: 34px;
          font-size: 10px;
          font-weight: bold;
          border-radius: 50%;
        }
      }
    }
  }