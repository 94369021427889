.dropdown {
    a {
        outline: none;
    }
}
.dropdown-menu {
    padding: 0;
    max-height: 225px;
    overflow-y: auto;
    border-bottom: none;
    border-radius: 0;
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
    &::-webkit-scrollbar {
    @media (max-width: $medium-screen) {
        width: 1px;
    }
        width: 4px;
    }
    &::-webkit-scrollbar-button {
        width: 0px;
        height: 0px;
    }
    &::-webkit-scrollbar-thumb {
        background: nth($scrollbar-colors, 2);
        border: 0px none $white;
        border-radius: 0px;
    }
    &::-webkit-scrollbar-track {
        background: nth($scrollbar-colors, 1);
        border: 0px none $white;
        border-radius: 0px;
    }
    &::-webkit-scrollbar-corner {
        background: transparent;
    }
}

.dropdown-item {
    position: relative;
    display: flex;
    padding: 8px 12px;
    font-size: 14px;
    border-bottom: 1px solid rgba(0,0,0,.15);
    &:hover {
        background-color: #e6e6e6;
    }
    i {
        margin-right: 10px;
    }
}

.c-dropdown {
    cursor: pointer;
    .dropdown-toggle {
        display: flex;
        align-items: center;
        height: 100%;
        outline: none;
    }
    .dropdown-menu {
        margin: 0;
        border: none;
        border-radius: unset;
        box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
    }
}