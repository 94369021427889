@mixin mq($respond, $breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media ($respond: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  } @else {
    @warn "Could not find `#{$breakpoint}` in both local ($tweakpoints) and global ($breakpoints) contexts. Media block omitted.";
  }
}

@mixin decorator-1() {
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  box-shadow: 0 0 16px #eaeaea;
  background: #fff;
}

@mixin scroll() {
  &::-webkit-scrollbar {
    @media (max-width: $medium-screen) {
      width: 1px;
    }
    width: 4px;
  }
  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }
  &::-webkit-scrollbar-thumb {
    background: nth($scrollbar-colors, 2);
    border: none;
    border-radius: 0;
  }
  &::-webkit-scrollbar-track {
    background: nth($scrollbar-colors, 1);
    border: none;
    border-radius: 0;
  }
  &::-webkit-scrollbar-corner {
    background: transparent;
  }
}
