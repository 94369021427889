.unit-invoices__footer {
  background: nth($component-colors, 1);
  padding: 25px 15px;
  width: 100%;
  .summary-box {
    display: inline-flex;
    flex-direction: row;
    align-items: stretch;
    width: 100%;
    &.green {
      .icon {
        i {
          color: nth($text-colors, 2);
        }
      }
      .box {
        background: nth($background-colors, 3);
        h3,
        h4,
        h5 {
          color: nth($text-colors, 6);
        }
      }
    }
    .icon {
      padding: 12px 10px 12px 0px;
      width: 55px;
      text-align: right;
      i {
        font-size: 35px;
        color: nth($text-colors, 1);
      }
    }
    .box {
      background: $white;
      border-radius: 0px 5px 5px 0px;
      padding: 10px 15px;
      box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
      width: 100%;
      .summary-amounts {
        text-align: right;
        h3,
        h5 {
          display: inline-block;
        }
        h5 {
          font-weight: 300;
          font-size: 14px;
          color: nth($text-colors, 3);
          text-transform: uppercase;
          margin-left: 5px;
          margin-bottom: 2px;
        }
      }
      h3 {
        color: nth($text-colors, 2);
        font-size: 21px;
        margin-bottom: 2px;
        @media (max-width: $medium-screen) {
          font-size: 18px;
        }
      }
      h4 {
        color: nth($text-colors, 3);
        font-size: 14px;
        margin-bottom: 0px;
      }
      h3,
      h4 {
        font-weight: bold;
        text-align: right;
      }
    }
  }
}

.unit-invoices-table {
  margin-top: 10px;
}