@import "../../assets/styles/base/colors";
@import "../../assets/styles/base/settings";

.error{
  &.error-message{
    color: $bright-red;
    margin-top: 2px;
    font-size: 12px;
    padding-left: 2px;
  }
}

input{
  &.error{
    border: 1px solid $light-red;
    -webkit-box-shadow: 0px 0px 23px 1px $light-red;
    -moz-box-shadow: 0px 0px 23px 1px $light-red;
    box-shadow: 0px 0px 5px 0px $light-red;
  }
}

.register-user { 
  display: flex;
  flex-direction: column;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
  }
}

@media (min-width: $small-screen) {
  .register-user {
    max-width: 750px;
    margin: 0 auto;
  }
  .register-user-confirmation-phone-row,
  .register-user-confirmation-email-row {
    margin-left: 70px;
    padding-left: 70px;
    background: url('../../assets/images/phoneNoConfirmationGreen.svg') no-repeat left top;
    background-size: 70px 70px;
  }
  .register-user-confirmation-email-row {
    background-image: url('../../assets/images/emailConfirmationGreen.svg');
  }
  .contacts-form {
    width: 75%;
    margin: 0 auto;
  }
  .font-step-list-background {
    color: $brand-color-1;
  }
}

.bankid-content, .bisnode-content {
  display: flex;
  flex-direction: column;
}

.back-button-arrow,
.next-button-arrow {
  display: inline-block;
}
