.c-select {
    position: relative;
    overflow: hidden;
    flex: 1 1 auto;
    height: $input-height;
    &.c-select--link {
        .c-select__current  {
            height: auto;
            line-height: 1;
            background: none;
            border: none;
            padding-right: 30px;
            &::before {
                top: 3px;
            }
        }
    }
    &--open {
        overflow: visible;
        .c-select__current {
            &::before {
                transform-origin: 6px 3px;
                transform: rotate(180deg);
            }
        }
    }
    select {
        display: none;
        visibility: hidden;
    }
    .c-select__current {
        display: flex;
        align-items: center;
        position: relative;
        margin: 0;
        padding: 0px 36px 0 8px;
        height: 100%;
        font-size: 14px;
        background-color: #fff;
        border: 1px solid #dedede;
        border-radius: 3px;
        user-select: none;
        cursor: pointer;
        &::before {
            content: "";
            position: absolute;
            top: 12px;
            right: 10px;
            width: 0;
            height: 0;
            border: 6px solid transparent;
            border-color: #a0a0a0 transparent transparent transparent;
        }
        
        &--disabled {
            cursor: unset;
            background-color: nth($background-colors, 5);
        }

    }
    .c-select__content {
        position: absolute;
        z-index: 1;
        right: 0;
        left: 0;
        visibility: hidden;
        opacity: 0;
        border: 1px solid #e5e0df;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        user-select: none;
        transform: translateY(-10px);
        transition: transform .2s ease-out, opacity .3s ease;
        background: #fff;
        &--open {
            visibility: visible;
            opacity: 1;
            transform: translateY(0);
        }
    }
    .c-select__options {
        padding: 0;
        margin: 0;
        max-height: 235px;
        overflow-y: auto;
        list-style: none;
        &::-webkit-scrollbar {
            width: 4px;
            @media (max-width: $medium-screen) {
                width: 1px;
            }
        }
        &::-webkit-scrollbar-button {
            width: 0px;
            height: 0px;
        }
        &::-webkit-scrollbar-thumb {
            background: nth($scrollbar-colors, 2);
            border: 0px none $white;
            border-radius: 0px;
        }
        &::-webkit-scrollbar-track {
            background: nth($scrollbar-colors, 1);
            border: 0px none $white;
            border-radius: 0px;
        }
        &::-webkit-scrollbar-corner {
            background: transparent;
        }
    }
    .c-select__options-item {
        display: flex;
        justify-content: space-between;
        padding: 6px 8px;
        line-height: 1;
        font-size: 12px;
        background-color: #fff;
        border-bottom: 1px solid #e5e0df;
        cursor: pointer;
        &:hover {
            background-color: darken(#fbfbfb, 4%);
        }
        
        &:last-child {
            border-bottom: none;
        }
        &.selected {
            background: darken(#fff, 5%);
        }
    }
    .c-select__filter {
        padding: 10px 10px;
        input {
            padding: 0 8px;
            width: 100%;
            height: 32px;
            background: #ececec;
            border: none;
            border-bottom: 1px solid #d8d8d8;
            outline: none;
        }
    }
}