@import '../../../assets/styles/base/colors';

.svg-icon-wrapper {
  width: 50px;
  height: 50px;
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: -11px;

  &.active, &.visited {
    .cls-3 {
      fill: $brand-color-1;
    }  
  }
  .cls-1 {
    isolation: isolate;
  }
  .cls-2 {
    opacity: 0;
    mix-blend-mode: multiply;
  }
  .cls-3 {
    fill: nth($text-colors, 3);
  }
}
