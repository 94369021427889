@import "../../../assets/styles/base/colors";

.billing-address {
  label {
    font-weight: 600;
    margin: 5px 0px;
  }
}

.creditor-data {
  margin-bottom: 10px;
}

.productCode {
  font-size: 16px;
  font-weight: 1000;
  color: black;
  margin: 0px 0px;
}

.productName {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.3;
  color: var(--brand-color-1);
  margin-top: 15px;
  margin-bottom: 15px;
}

.productValue {
  font-size: 20px;
  font-weight: 600;
  color: var(--brand-color-1);
  margin-top: 20px;
}

.vat {
  font-size: 13px;
  font-weight: 200;
  color: black;
  margin: 5px 0px 10px 0px;
}

.productValueGroup {
  margin: 0px;
  padding: 0px;
}

.productDescription {
  font-size: 13px;
  font-weight: 200;
  color: black;
  margin: 10px 0px;
}

.addToBasket {
  float: right;
}

.amount {
  color: var(--brand-color-1);
  font-size: 22px;
  font-weight: 600;
}

.annotation {
  font-size: 10px;
  font-weight: 200;
  color: black;
  float: right;
}
.notification {
  font-size: 10px;
  text-align: center;
}

.label {
  margin-top: 6px;
  font-size: 14px;
  text-align: right;
  padding: 0px;
}

.center {
  height: 50vh;
}

.tableProducts {
  padding: 0;
}