.accordion {
  &__wrapper {
    border: 1px solid #efefef;
  }
  &__head {
    background: #fdfdfd;
    border-bottom-color: #efefef;
    text-transform: uppercase;
    font-size: 12px;
  }
}

.carousel-item > img {
  width: 100%;
  height: 250px;
}
