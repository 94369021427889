.c-timeline {
  .c-timeline__title {
    margin: 10px 0 0 0;
  }
  .c-timeline__nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0 10px 0;
    background: #efefef;
    button {
      height: 18px;
      color: darken(#efefef, 40%);
    }
  }
  .c-timeline__scrollbar {
    flex: 1;
    margin: 0;
    .vis-timeline {
      border: none;
    }
  }
}

.vis-timeline {
  .vis-item {
    &.vis-box {
      width: 38px;
      height: 38px;
      border-radius: 50%;
    }
    &.other {
      border-color: var(--brand-color-1);
      background-color: var(--brand-color-1);
    }
    &.messages {
      border-color: #e99486;
      background-color: #e99486;
    }
    &.f1 {
      border-color: #1ba7dc;
      background-color: #1ba7dc;
    }
    .vis-item-content {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      width: 100%;
      height: 100%;
    }
    &.range-timeline {
      background-color: darken(#efefef, 30%);
      &--hide-vertical-scroll {
        visibility: hidden;
      }
    }
  }
  div.vis-tooltip {
    padding: 16px;
    background: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 0;
    h1 {
      font-weight: 600;
      font-size: 14px;
    }
    p {
      margin: 0;
      font-size: 12px;
    }
  }
  border-color: #dedede;
}

.vis-panel {
  &.vis-cente {
    border-color: #e6e6e6;
  }
  .vis-shadow {
    display: none;
  }
}

.vis-time-axis {
  .vis-grid.vis-minor {
    border: none;
  }
}

.vis-time-axis {
  &.vis-foreground {
    background: #dcdcdc;
  }
  .vis-text {
    color: #636363;
    font-size: 12px;
    text-align: center;
    font-weight: 600;
    background-color: #efefef;
    border-left: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
  }
  .vis-major {
    background: #dcdcdc;
  }
}
