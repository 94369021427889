.input {
}

.radioWrapper {
    display: flex;
}

.radioInput {

}

.radioInputChecked {

}

.radioInputSecondary {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 20px 0 0;
    text-align: center;;
    color: #909090;
    border: 1px solid #909090;
    transition: border-color .3s ease;
    &:hover {
        border: 1px solid rgba(40, 167, 69, 0.5);
    }
    &.radioInputChecked {
        color: #28A745;
        border-color: #28A745;
        i {
            opacity: 1;
        }
    }
    input {
        visibility: hidden;
        position: absolute;
    }
    i {
        opacity: 0;
        margin: 0 0 10px 0;
    }
    label {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 20px;
        width: 100px;
        height: 90px;
        cursor: pointer;
    }
}