$button-max-height: 30px;
$button-max-width: 255px;
$default-box-shadow: 0 3px 12px 0 #ddd, 3px 3px 9px 6px rgba(0, 0, 0, 0);
$default-font:PT Sans;
$default-text-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
$error-background-height-number: 961px;
$error-background-width-number: 1109px;
$footer-height: 55px;
$navbar-height: 60px;
$header–with-footer-height: $footer-height + $navbar-height;
$header–with-footer-height-mobile: $footer-height + $navbar-height + $navbar-height;
$xl-screen: 1200px;
$large-screen: 992px;
$medium-screen: 768px;
$min-margin: 10px;
$small-screen: 576px;
$vertical-divider-height: $navbar-height - 15px;
$leftMenuWidth: 230px;
$transitionWidth: transform .3s ease-in-out, width .3s ease-in-out;
$transition: 0.3s all ease;

$breakpoints: (
  'small': 576px,
  'medium': 768px,
  'large': 992px,
) !default;