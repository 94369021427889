@import './colors';

// Reset Bootsrtap padding styles
.container, .container-fluid {
  padding-left: 8px;
  padding-right: 8px;
  @include mq('min-width', 'xxl') {
    max-width: 1600px;
  }
}
.container--force {
  margin: 0;
  padding: 0;
  max-width: 1140px !important;
}
.row {
  margin-left: -8px;
  margin-right: -8px;
  &--aligned {
    align-items: center;
  }
}
.col{
  &--verticaly-aligned{
    display: flex;
    align-items: center;
  }
  &--horizontaly-aligned{
    display: flex;
    justify-content: center;
  }
}
.col,
[class*="col-"] {
  padding-right: 8px;
  padding-left: 8px; 
}

.col-xxl-1 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

@for $i from 1 through 12 {
  .col-xxl-#{$i} {
    @include mq('min-width', 'xxl') {
      max-width: calc(100% / (12 / #{$i}));
      flex-basis: calc(100% / (12 / #{$i}));
    }
  }
}

hr {
  &.hr--both{
    clear: both;
  }
}

.ant-time-picker-icon {
  z-index: 0;
}

a {
  color: nth($text-colors, 1);
}

// pagination
.react-bootstrap-table-pagination {
  .pagination {
    .page-item {
      &.active {
        .page-link {
          border-radius: 3px;
        }
      }
      .page-link {
        background: transparent;
        border: none;
        border-radius: 3px;
      }
    }
  }
}