@import "../../assets/styles/base/colors";
@import "../../assets/styles/base/settings";
@import "../../assets/styles/helpers/variables";
@import "../../assets/styles/helpers/mixins";

.c-modal {
  display: flex;
  justify-content: center;
  &--lg {
    max-width: 900px;
  }
  &--xl {
    max-width: 1200px;
  }
  &--float {
    width: auto;
    max-width: none;
    .modal-content {
      width: auto;
    }
  }
  .c-modal__header {
    background-color: nth($background-colors, 1);
    color: nth($text-colors, 6);
    padding: 5px 15px;
    h5 {
      font-weight: bold;
    }

    i {
      padding-right: 10px;
    }
  }
}

.c-modal-body {
  background-color: nth($background-colors, 2);
  .c-modal-body__actions {
    display: flex;
    justify-content: space-between;
  }
}

.modal {
  &--full {
    width: 80vw;
    max-width: 1340px;
    height: 90vh;
    max-height: 840px;
  }
  .modal-header {
    align-items: center;
    .close {
      margin: 0;
      padding: 0;
      color: nth($text-colors, 6);
      text-shadow: none;
      transition: opacity 0.3s ease;
    }
  }
  .modal-dialog {
    @include mq("max-width", "lg") {
      margin: 5%;
      max-width: 90%;
    }
  }
}

.flex-modal {
  display: flex;

  .modal-body {
    background-color: nth($background-colors, 2);
  }

  .modal-header {
    background-color: nth($background-colors, 1);
    color: nth($text-colors, 6);
    padding: 5px 15px;

    h5 {
      font-weight: bold;
    }

    i {
      padding-right: 10px;
    }
  }
}
